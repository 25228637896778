.home-grid
  display: grid
  padding: 1rem 0 1rem 1rem
  grid-gap: 1rem
  grid-template-columns: 2fr 1fr
  @media (max-width: 768px)
    padding: 1rem
.category-grid
  display: grid
  padding: 1rem
  grid-gap: 1rem
  grid-template-columns: 1fr

.product-grid-home
  .products-home
    display: grid
    grid-gap: 1rem
    grid-template-columns: repeat(5, 1fr)
    .product-item
      width: calc(100vw/6 - 1rem - 2rem/6 - 2px)
    @media (max-width: 1600px)
      grid-template-columns: repeat(4, 1fr)
      .product-item
        width: calc(100vw/5 - 1rem - 2rem/5 - 2px)
    @media (max-width: 1200px)
      grid-template-columns: repeat(3, 1fr)
      .product-item
        width: calc(100vw/4 - 1rem - 2rem/4 - 2px)
    @media (max-width: 992px)
      grid-template-columns: repeat(2, 1fr)
      .product-item
        width: calc(100vw/3 - 1rem - 2rem/3 - 2px)
    @media (max-width: 768px)
      grid-template-columns: repeat(3, 1fr)
      .product-item
        width: calc(100vw/3 - 1rem - 2rem/3 - 2px)
    @media (max-width: 650px)
      grid-template-columns: repeat(2, 1fr)
      .product-item
        width: 100%
    @media (max-width: 420px)
      grid-template-columns: repeat(1, 1fr)
      .product-item
        width: 100%

.product-grid
  .products
    display: grid
    grid-gap: 1rem
    grid-template-columns: repeat(6, 1fr)
    .product
      width: calc(100vw/6 - 1rem - 2rem/6)
    @media (max-width: 1600px)
      grid-template-columns: repeat(5, 1fr)
      .product
        width: calc(100vw/5 - 1rem - 2rem/5)
    @media (max-width: 1200px)
      grid-template-columns: repeat(4, 1fr)
      .product
        width: calc(100vw/4 - 1rem - 2rem/4)
    @media (max-width: 992px)
      grid-template-columns: repeat(3, 1fr)
      .product
        width: calc(100vw/3 - 1rem - 2rem/3)
    @media (max-width: 650px)
      grid-template-columns: repeat(2, 1fr)
      .product
        width: 100%
    @media (max-width: 420px)
      grid-template-columns: repeat(1, 1fr)
      .product
        width: 100%

.post-grid
  .posts
    display: grid
    grid-gap: 1rem
    grid-template-columns: repeat(1, 1fr)
  .img-fluid
    width: 100%
