label
  margin-bottom: .125rem
.form-group
  margin-bottom: .25rem
.form-control
  appearance: auto !important
.p-inputtext
  height: auto !important
// same as buttons
// .input-group-sm>.form-control:not(textarea)
//   height: calc(1.5rem + .5rem + 2px)
