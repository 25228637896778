.em-1
  width: 1em !important
.em-2
  width: 2em !important
.em-3
  width: 3em !important
.em-4
  width: 4em !important
.em-5
  width: 5em !important
.em-6
  width: 6em !important
.em-7
  width: 7em !important
.em-8
  width: 8em !important
.em-9
  width: 9em !important
.em-10
  width: 10em !important
.em-15
  width: 15em !important
.em-20
  width: 20em !important
.em-25
  width: 25em !important
.em-30
  width: 30em !important
.em-40
  width: 40em !important
.em-50
  width: 50em !important
.em-60
  width: 60em !important
.em-70
  width: 70em !important
.em-80
  width: 80em !important
.em-100
  width: 100em !important
.vh100
  height: 100vh
.vw100
  width: 100vw
.w-5
  width: 5%
.w-10
  width: 10%
.font-sm
  font-size: .75rem
.font-md
  font-size: 1rem
.hidden-xs-up
  display: none !important
@media (max-width: 575px)
  .hidden-xs-down
    display: none !important
@media (min-width: 576px)
  .hidden-sm-down-up
    display: none !important
@media (max-width: 767px)
  .hidden-sm-down
    display: none !important
@media (min-width: 768px)
  .hidden-md-up
    display: none !important
@media (max-width: 991px)
  .hidden-md-down
    display: none !important
@media (min-width: 992px)
  .hidden-lg-up
    display: none !important
@media (max-width: 1199px)
  .hidden-lg-down
    display: none !important
@media (min-width: 1200px)
  .hidden-xl-up
    display: none !important
.hidden-xl-down
  display: none !important
.visible-print-block
  display: none !important
@media print
  .visible-print-block
    display: block !important
.visible-print-inline
  display: none !important
@media print
  .visible-print-inline
    display: inline !important
.visible-print-inline-block
  display: none !important
@media print
  .visible-print-inline-block
    display: inline-block !important
@media print
  .hidden-print
    display: none !important

