.dropdown-menu
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, .5)
  z-index: 1060

.dropdown-menu-fixed
  position: fixed !important
  top: var(--navbarHeight) !important
  transform: none !important
  @media (max-width: 767px)
    top: auto !important
    bottom: var(--navbarHeight) !important

.dropdown-toggle
  padding: 0 !important
  padding: 0
  color: var(--navColor)

.dropdown-toggle::after
  display: none !important

.menu, .sub
  min-width: 12rem !important
  text-transform: capitalize
  color: white

.menu
  padding-bottom: .5rem

.menu>span
  border-bottom: 1px solid white

.menu>a
  border-bottom: 1px solid transparent

.sub
  font-size: .75rem
  padding: .5rem 1rem 0 1rem
  border-bottom: .2rem solid transparent
  margin-bottom: .3rem

.sub:hover
  border-bottom: .2rem solid white

::ng-deep
  .p-dropdown-trigger
    ::before
      font-family: "Font Awesome 5 Free"
      font-weight: 900
      z-index: 110
      content: "\f078"
    .p-dropdown-trigger-icon
      ::before
        content: "\f078"
