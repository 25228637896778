// .btn-group-sm>.btn, .btn-sm
//   font-size: .75rem
//   height: 1.75rem
//   margin: .125rem .125rem .125rem .125rem
//   padding: .25rem .5rem
//   border: 0
//   @media only screen and (max-width: 767px)
//     padding: 0.2rem 0.3rem !important
//     font-size: .85rem
// .btn
//   .fa
//     width: .75rem
//     height: .75rem
//     // margin: 0.125rem
//     font-size: 1rem
//   .fa-2x
//     width: 2rem
//     height: 2rem
// // .btn-no-cursor
// //   cursor: none
// .btn-no-cursor
//   border: 0
// .btn-no-cursor:hover
//   cursor: none

// .btn-dark.btn-no-cursor:hover, .btn-dark.btn-no-cursor:focus, .btn-dark.btn-no-cursor.focus
//   color: var(--white)
//   background-color: var(--dark)
//   border-color: var(--dark)
//   opacity: 1

// .btn-no-border
//   border-color: transparent

// // .btn-circle
// //   width: 2.5rem
// //   height: 2.5rem
// //   line-height: 2.5rem
// //   padding: 0px
// //   border-radius: 50%
// //   color: var(--dark)
// //   border: 1px solid var(--dark)
// // .btn-circle.btn-nav
// //   color: var(--navColor)
// //   border-color: var(--navColor)
// //   &:hover
// //     opacity: .7

// .btn-square
//   border-radius: 0
// .fa
//   margin: 0 .2rem

// .upload-btn-wrapper
//   position: relative
//   overflow: hidden
//   display: inline-block
//   text-align: center
// .upload-btn
//   border: 1px solid var(--primary)
//   color: var(--white)
//   background-color: var(--primary)
//   padding: 0 .4rem
//   border-radius: 3px
//   font-size: 1.5rem
//   font-weight: bold
//   text-align: center
//   i.fa
//     height: 1.5rem !important
//     width: 1.5rem !important
//     padding: auto
//     margin: 0
// .upload-btn-wrapper input[type=file]
//   font-size: 100px
//   position: absolute
//   left: 0
//   top: 0
//   opacity: 0

// .btn-submit
//   width: 20vw
//   margin-top: 1rem
//   margin-left: 10vw

// .p-button.p-button-icon-only.p-button-rounded
//   padding: .125rem
//   width: 1.25rem
//   height: 1.25rem
//   .pi
//     font-size: .75rem
// .p-button.p-button-icon-only.p-button-rounded
//   color: #6c757d
//   border: 0 none
//   background: transparent
//   border-radius: 50%
//   transition: background-color 0.2s, color 0.2s, box-shadow 0.2s
// .p-button.p-button-icon-only.p-button-rounded:hover
//   color: #495057
//   border-color: transparent
//   background: #e9ecef

// .btn-nav
//   height: calc(1.5rem + .5rem + 2px)
//   // line-height: calc(var(--navbarHeight)/2)
//   background-color: var(--white)
//   border: 0
//   .hidden-sm-down
//     font-size: .5rem
//     text-transform: uppercase
//     font-weight: 700
//     height: calc(var(--navbarHeight)/2)
//     line-height: calc(var(--navbarHeight)/2)
//     padding-bottom: calc(var(--navbarHeight)/4)
//   .qty
//     color: var(--white)
//     background-color: var(--info)
//     margin-left: 0
//     width: 1.25rem
//     height: 1.25rem
//     font-size: .75rem
//     padding: 0 0.25rem
//     vertical-align: top
//     text-align: center
//     border-radius: 1.25rem
// .btn-admin
//   // background-color: var(--dark)
//   // color: var(--white)
//   border-radius: 3px
//   max-height: calc(var(--navbarHeight)/2)
.cursor-none
  cursor: none
.cursor-default
  cursor: default !important
.btn-outline-primary, .btn-outline-danger, .btn-outline-info, .btn-outline-warning, .btn-outline-dark
  background-color: transparent
.btn
  padding: .475rem .95rem
