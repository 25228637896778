.badge-lg, .badge-group-lg > .badge
  padding: 0.5rem 1rem
  font-size: 1.25rem
  line-height: 1.5
  border-radius: 0.3rem
.badge-outline-primary
  color: var(--primary)
  border: 1px solid var(--primary)

.p-badge-primary
  background-color: var(--primary) !important
.p-badge-success
  background-color: var(--success) !important
.p-badge-danger
  background-color: var(--danger) !important
.p-badge-info
  background-color: var(--info) !important
.p-avatar-circle img
  border-radius: 50%
.p-badge-outlined
  background-color: transparent !important
  color: var(--dark) !important
  border: 1px solid var(--dark) !important
