
.alert-sm
  padding: 0 10px

.alert-danger
  color: var(--white)
  background-color: var(--danger)
  border-color: transparent

.alert-warning
  background-color: var(--warning)

.alert-warning
  p:last-child
    margin-bottom: 0

