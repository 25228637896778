.p-card
  margin: .5rem
.p-card .p-card-content
  padding: 0
.y-card
  background-color: #fff
  margin-bottom: .75rem
  padding: 1rem
  border-radius: 5px
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px
.y-card:hover
  box-shadow: rgba(17, 17, 26, 0.05) 0px 0px 16px
